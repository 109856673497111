'use strict';
import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';

angular.module('core.tapcolors.directives', [])
    .directive('tapColors', TapColorsDirective);

/**
 * TapColors UI Directive*
 * As much as possible, do not transclude an input tag in the slot of this directive. It was only
 *      used to support legacy pages where it was jquery dominated. Use angular runtime context instead.
 *      Ex: A model which contains a color property which needs to be passed in the 'scope.color' attribute
 *
 * @ngInject
 */
function TapColorsDirective (
    TapColorsService,
    TapColorsModelFactory,
    $TapColorsEvents,
    PubSub
) {
    return {
        restrict: 'EA',
        scope: {
            autoShow: '@',
            animationSpeed: '@',
            color: '<',
            initialColor: '<',
            colors: '<',
            colorChange: '&',
            onOpen: '<', // function callback
            onClose: '<', // function callback
            dismissIfOutside: '@',
            dismissOnSelection: '@',
            size: '@',
            showInput: '@',
            tapColorId: '<',
            allowNull: '<',
        },
        transclude: true,
        template: '<div id="{{tapColorId}}" class="color-picker-input" style="background-color: {{color}}">' +
            '<span class="no-color-marker" ng-if="!color">?</span>' +
            '<div ng-transclude></div>' +
            '</div>',
        link: function (scope, element, attrs) {

            scope.$on('$destroy', function () {
                PubSub.off($TapColorsEvents.FORCE_CLOSE + scope.tapColorId, _forceCloseEventHandler);
                PubSub.off($TapColorsEvents.UPDATE_COLOR + scope.tapColorId, _updateColorHandler);
            });
            if (scope.tapColorId) {
                PubSub.on($TapColorsEvents.FORCE_CLOSE + scope.tapColorId, _forceCloseEventHandler);
                PubSub.on($TapColorsEvents.UPDATE_COLOR + scope.tapColorId, _updateColorHandler);
            }

            var div = element.children()[0];

            // See if an element was added in the slot
            scope.transcluded = $(element).find('[ng-transclude] *').length > 0;

            var input;
            var tapColor;
            if (scope.transcluded) {
                input = $(element).find('[ng-transclude] *')[0];
                $(input).css('opacity', 0);
            }

            _init();

            function _init() {
                var options = {
                    autoShow: scope.autoShow                        || TapColorsService.autoShow,
                    animationSpeed: scope.animationSpeed            || TapColorsService.animationSpeed,
                    color: scope.color                              || TapColorsService.defaultColor,
                    colors: scope.colors                            || TapColorsService.colors,
                    dismissIfOutside: scope.dismissIfOutside        || TapColorsService.dismissIfOutside,
                    dismissOnSelection: scope.dismissOnSelection    || TapColorsService.dismissOnSelection,
                    size: scope.size                                || TapColorsService.size,
                    showInput: scope.showInput                      || true,
                    initialColor: scope.initialColor                || undefined,
                    allowNull: scope.allowNull                      || false
                };

                tapColor = TapColorsModelFactory.getTapColorsModel(div, {
                    size: options.size,
                    colors: options.colors,
                    defaultColor: options.color,
                    dismissOnSelection: options.dismissOnSelection.bool(),
                    dismissIfOutside: options.dismissIfOutside.bool(),
                    animationSpeed: options.animationSpeed,
                    autoShow: options.autoShow.bool(),
                    initialColor: options.initialColor,
                    allowNull: options.allowNull
                });

                // Color selected from the picker
                tapColor.on('change', function (newValue) {
                    TapColorsService.isActive = false;
                    if (input) {
                        $(input).val(newValue);
                    }
                    $(div).css('background-color', newValue);
                    scope.$evalAsync(function () {
                        scope.color = newValue;
                        scope.colorChange({
                            newValue: newValue
                        })
                    })
                });

                tapColor.on('open', function () {
                    TapColorsService.isActive = true;
                    scope.onOpen && scope.onOpen();
                });

                tapColor.on('close', function () {
                    TapColorsService.isActive = false;
                    scope.onClose && scope.onClose();
                });

                // To support legacy code, if input value changes, must reactively update the rest
                if (input) {
                    $(input).on('change', function () {
                        var inputColor = $(input).attr('value');
                        if (_.isNil(inputColor)) {
                            return;
                        }
                        $(div).css('background-color', inputColor);
                        scope.color = inputColor;
                        scope.colorChange({
                            newValue: scope.color
                        })
                    });
                    $(input).on('textchange', function () {
                        var inputColor = $(input).val();
                        if (_.isNil(inputColor)) {
                            return;
                        }
                        $(div).css('background-color', inputColor);
                        tapColor.options.defaultColor = inputColor;
                        scope.color = inputColor;
                    });
                }

                if (scope.showInput && !scope.showInput.bool()) {
                    $(div).addClass('color-picker-input-hidden');
                }
            }

            function _updateColorHandler(color) {
                scope.color = color;
                tapColor.color = color;
            }

            function _forceCloseEventHandler() {
                if (TapColorsService.isActive) {
                    tapColor.dismiss();
                    TapColorsService.isActive = false;
                    scope.onClose && scope.onClose();
                }
            }
        }
    };
}