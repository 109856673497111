import { MapChart, geoMercator, ZoomControl } from '@amcharts/amcharts5/map';
import { useGeoSeries } from '@/modules/core/charts/am5/geo/composables/useGeoSeries';
import {
  AM5_COUNTRY_DRILLDOWN,
  VISIBLE,
  ZOOM_CONTROL,
  STATE,
  DRILLDOWN,
  CLICK,
  NAME,
} from '@/modules/core/charts/am5/geo/geo.charts.constants';
import EventBus from '@/modules/core/app/helpers/EventBus';

export function useMaps(context) {
  const { root, chart, config } = context();
  const { createAllSeries } = useGeoSeries(context);
  function initMaps() {
    createMaps();
    createZoomControls();
    createAllSeries();
    root.value.numberFormatter.setAll({
      numberFormat: '#,###.##',
    });
  }
  function zoomOut() {
    const state = config.value.get(STATE);
    if (state?.isBuilding) {
      EventBus.signal(AM5_COUNTRY_DRILLDOWN, {
        map_id: null,
      });
    }
    for (let id = chart.value.series.values.length - 1; id >= 0; id--) {
      const series = chart.value.series.getIndex(id);
      if (series.get(NAME).indexOf(DRILLDOWN) !== -1) {
        series.hide();
      } else {
        series.show();
      }
    }
    chart.value.goHome();
  }
  function createMaps() {
    const mapZoom = config.value.widgetData.metadata.map_zoom;
    chart.value = root.value.container.children.push(
      MapChart.new(root.value, {
        projection: geoMercator(),
        layout: root.value.verticalLayout,
        homeZoomLevel: mapZoom?.zoomLevel,
        homeGeoPoint: { longitude: mapZoom?.zoomLongitude, latitude: mapZoom?.zoomLatitude },
      })
    );
  }
  function createZoomControls() {
    const zoomControl = chart.value.set(
      ZOOM_CONTROL,
      ZoomControl.new(root.value, {
        x: 0,
        centerX: 0,
        y: 0,
        centerY: 0,
      })
    );
    zoomControl.homeButton.set(VISIBLE, true);
    zoomControl.homeButton.events.on(CLICK, () => {
      zoomOut();
    });
  }
  return {
    initMaps,
  };
}
