import { differenceBy, isNil, cloneDeep } from 'lodash';
import { getters } from '@/modules/core/app/helpers/store';
import { RelativeDateRangeKey } from '@/modules/core/daterange/daterange.constants';
import { DateRangeDescription } from '@/modules/ta/widget/models/DateRangeDescription';
import { BaseUIService } from '@/modules/core/ui/services/BaseUIService';
import { DateUtil } from '@/modules/core/app/utils/DateUtil';
import { NoDataAction } from '@/modules/ta/widget/models/NoDataAction';
import { DrawOption, WidgetState, WidgetType } from '@/modules/ta/widget/widget.constants';
import WidgetContextFactory from '@/modules/ta/widget/models/context/WidgetContextFactory';

export class WidgetRenderService extends BaseUIService {
  /**
   * @var {string}
   */
  widgetId;

  /**
   * @var {Array}
   */
  nonGroupedColumns;

  /**
   * @var {Array}
   */
  currentPeriodData = [];

  /**
   * @var {Array}
   */
  priorPeriodData = [];

  /**
   * @type {number}
   */
  totalData = 0;

  constructor(widgetId) {
    super();
    this.widgetId = widgetId;
  }

  reset() {
    this.totalData = 0;
    this.nonGroupedColumns = null;
    this.currentPeriodData = [];
    this.priorPeriodData = [];
  }

  /**
   * Hook to refresh/reset properties
   */
  preReRender() {
    this.reset();
  }

  /**
   * Retrieves widget from the store
   * @returns {Widget}
   */
  getWidget() {
    return getters.widget.getWidget(this.widgetId);
  }

  /**
   * Retrieves widget state from the store
   * @returns {*}
   */
  getState() {
    return getters.widget.getWidgetState(this.widgetId);
  }

  /**
   * @returns {GenericDataModel[]}
   */
  getData() {
    return cloneDeep(this.getWidget()?.cached_data);
  }

  /**
   * @returns {BaseDrawOption}
   */
  getDrawOptions() {
    return this.getWidget()?.metadata.draw_options;
  }

  /**
   * Theme color of the app
   * @returns {string}
   */
  getCurrentThemeColor() {
    return getters.session.getUserSettings()?.currentThemeColor;
  }

  /**
   * @returns {boolean}
   */
  isDrillDownEnabled() {
    return this.getWidget()?.isDrillDownEnabled();
  }

  /**
   * @returns {boolean}
   */
  isMultiGrouped() {
    return this.getWidget()?.isMultiGrouped();
  }

  /**
   * @returns {boolean}
   */
  isExporting() {
    return getters.export.isExporting();
  }

  /**
   * @returns {boolean}
   */
  isScheduledReport() {
    return getters.export.isScheduledExport();
  }

  /**
   * @returns {boolean}
   */
  isEditing() {
    return this.getWidget()?.id === getters.widgetBuilder.getEditingWidget()?.id;
  }

  getDateRange() {
    return getters.daterange.getDateRange();
  }

  /**
   * @returns {boolean}
   */
  hasComparison() {
    if (this.getWidget()?.metadata.compare_to_prior_period) {
      return true;
    }
    return this.getDateRange()?.is_comparison_active;
  }

  /**
   * Return font size.
   * @returns {*}
   */
  getFontSize() {
    return this.getDrawOptions().font_size;
  }

  /**
   * @returns {boolean}
   */
  canShowDelete() {
    const widget = this.getWidget();
    return widget?.can_be_edited && !widget?.is_predefined;
  }

  /**
   * @returns {NoDataAction}
   */
  getNoDataAction() {
    return NoDataAction.initDefault();
  }

  /**
   * @returns {DateRangeDescription}
   */
  getDateRangeDescription() {
    const widget = this.getWidget();
    return new DateRangeDescription({
      fromTitle: this._getFromDateRangeText(widget),
      fromRange: this._getFromDateRange(widget),
      toTitle: this._getComparisonDateRangeFromText(widget),
      toRange: this._getComparisonFromDateRange(widget),
    });
  }

  /**
   * @returns {Boolean}
   */
  isSampleData() {
    return this.getWidget().metadata.draw_options[DrawOption.FORCE_SAMPLE_DATA];
  }

  _getFromDateRangeText(widget) {
    let fromText = i18n.$t('Date Range from');

    if (widget.metadata.relative_date_range === RelativeDateRangeKey.CUSTOM) {
      fromText = `Custom ${fromText}`;
    }

    return fromText;
  }

  _getFromDateRange(widget) {
    let text = '';

    if (widget.metadata.start_date_override) {
      text = this._formatDateRangeText(
        widget.metadata.start_date_override,
        widget.metadata.end_date_override
      );
    } else {
      const ranges = getters.daterange.getRelativeDateRanges();
      text = this._formatDateRangeText(
        ranges[widget.metadata.relative_date_range].start,
        ranges[widget.metadata.relative_date_range].end
      );
    }

    return text;
  }

  /**
   * Returns if the widget content should be shown or not.
   * @returns {boolean}
   */
  canShowWidgetContent() {
    const state = this.getState();
    return state === WidgetState.STATE_DONE || state === WidgetState.STATE_TOO_MANY_DATA;
  }

  _getComparisonDateRangeFromText(widget) {
    return widget.metadata.compare_to_prior_period ? i18n.$t('Comparing to') : '';
  }

  _getComparisonFromDateRange(widget) {
    let text = '';
    if (!widget.metadata.compare_to_prior_period) {
      return text;
    }

    if (widget.metadata.comparison_start_date_override) {
      text = this._formatDateRangeText(
        widget.metadata.comparison_start_date_override,
        widget.metadata.comparison_end_date_override
      );
    } else {
      const ranges = getters.daterange.getRelativeDateRanges();
      text = this._formatDateRangeText(
        ranges[widget.metadata.comparison_relative_date_range].start,
        ranges[widget.metadata.comparison_relative_date_range].end
      );
    }

    return text;
  }

  _formatDateRangeText(start, end) {
    return `${DateUtil.formatDate(start)} to ${DateUtil.formatDate(end)}`;
  }

  /**
   * @returns {Array}
   */
  getFullChartPalette() {
    const widget = this.getWidget();
    return WidgetContextFactory.getContext()?.getChartPalette(widget);
  }

  /**
   * @param columnIndex
   * @returns {string}
   */
  getChartPalette(columnIndex) {
    const widget = this.getWidget();
    const chartPalette = WidgetContextFactory.getContext()?.getChartPalette(widget);
    return chartPalette && chartPalette[columnIndex % chartPalette.length];
  }

  /**
   * @param value
   * @returns {Array}
   */
  setNonGroupedColumns(value = null) {
    if (value) {
      this.nonGroupedColumns = value;
    }
    if (this.nonGroupedColumns) {
      return this.nonGroupedColumns;
    }

    const widget = this.getWidget();
    const { selected = [], grouped = [] } = widget ? widget.metadata.data_columns : {};
    if (this._widget?.type === WidgetType.TYPE_PIECHART) {
      this.nonGroupedColumns = differenceBy(selected, grouped, 'field').filter((column) =>
        column.isFormatInteger()
      );
    } else {
      this.nonGroupedColumns = differenceBy(selected, grouped, 'field').filter((column) =>
        column.isFormatNumerical()
      );
    }
  }

  /**
   * @returns {Array}
   */
  getGroupedColumns() {
    return this.getWidget()?.getGroupedColumns() || [];
  }

  setCurrentAndPriorPeriodData() {
    const data = this.getData();
    if (isNil(data)) {
      return;
    }

    this.totalData = data.length;

    data.forEach((datum) => {
      if (datum.current_period) {
        this.currentPeriodData.push(datum.current_period);
        this.priorPeriodData.push(datum.prior_period);
      } else {
        this.currentPeriodData.push(datum);
      }
    });
  }

  setup() {
    this.reset();
    this.setNonGroupedColumns();
    this.setCurrentAndPriorPeriodData();
  }
}
