export const SessionVars = {
  LOGIN_REDIRECT_PATH: 'loginRedirectPath',
  IS_NAV_COLLAPSED: 'isNavCollapsed',
  PERSIST_ADVANCE_FILTERS: 'PersistAdvanceFilters',
};

export const InstanceStatus = {
  SUSPENDED_UNPAID: 'suspended_unpaid',
  SUSPENDED_INACTIVE: 'suspended_inactive',
};

export const LoginMessageEnum = {
  EMAIL_INSTRUCTION_RESET_PASSWORD: 1,
  PASSWORD_RESET_SUCCESSFULLY: 2,
  LOGIN_LINK_INVALID: 3,
  LINK_EXPIRED_NEW_LINK_RESENT: 4,
  LOGIN_LINK_USER_INACTIVE: 5,
  LOGIN_LINK_CLIENT_INACTIVE: 6,
  NO_USER_FOR_EMAIL_IN_PLATFORM: 7,
  SOMETHING_WENT_WRONG: 8,
  PASSWORD_RESET_LINK_EXPIRED: 9,
  PASSWORD_RESET_LINK_RESENT: 11,
  INSTANCE_SUSPENDED: 10,
};

export const LoginMessage = {
  [LoginMessageEnum.EMAIL_INSTRUCTION_RESET_PASSWORD]:
    'We will send a password reset link if we find your email in our database.',
  [LoginMessageEnum.PASSWORD_RESET_SUCCESSFULLY]:
    'Your password was successfully reset and you can now log in with it.',
  [LoginMessageEnum.LOGIN_LINK_INVALID]:
    'The login link you just clicked is invalid. Please log in below.',
  [LoginMessageEnum.LINK_EXPIRED_NEW_LINK_RESENT]:
    'The link you clicked has expired, so we just sent you a new one. Check your inbox for it!',
  [LoginMessageEnum.LOGIN_LINK_USER_INACTIVE]:
    'The login link you clicked is for a user that is no longer active.',
  [LoginMessageEnum.LOGIN_LINK_CLIENT_INACTIVE]:
    'The login link you clicked is for a user whose client is no longer active.',
  [LoginMessageEnum.NO_USER_FOR_EMAIL_IN_PLATFORM]:
    'There is no user with the same email address as you have in the source platform here. Please contact your agency to gain access to the dashboard.',
  [LoginMessageEnum.SOMETHING_WENT_WRONG]:
    'Something went wrong. Please use the regular login form.',
  [LoginMessageEnum.PASSWORD_RESET_LINK_EXPIRED]:
    'This password reset link has expired. Please request a new one.',
  [LoginMessageEnum.PASSWORD_RESET_LINK_RESENT]:
    ' The password reset link has expired. We have sent a new link to your email address.',
  [LoginMessageEnum.INSTANCE_SUSPENDED]:
    'There is no access to this dashboard at this time. Please contact your agency representative for support.',
};

export const SessionResponseType = {
  PASSWORD_LINK_EXPIRED: 'passwordLinkExpired',
  PASSWORD_LINK_RESENT: 'passwordLinkResent',
  SUCCESS_LOGIN: 'success_login',
  EXPIRED_PASSWORD: 'expired_password',
  EXTERNAL_URL: 'external_url',
  PASSWORD_RESET_SUCCESS: 'success',
};

export const FeaturePackage = {
  GDS_TAPFUSION: 'TapFusion - Temp',
  SUP: 'SUP',
  STANDARD: 'Standard',
};

export const InvoiceHandling = {
  GCP: 'gcp',
  DCP: 'dcp',
  CHARGEBEE: 'chargebee',
};

export const PlatformSource = {
  TAPREPORTSFREEMIUM: 'tapreportsfreemium',
};

export const DEFAULT = 'default';
