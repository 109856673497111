import { Label, Legend, p100, Scrollbar, percent, color } from '@amcharts/amcharts5';
import { Constant, Position, WidgetEvents } from '@/modules/core/charts/am5/charts.constants';
import { omitNils } from '@/modules/core/app/utils/ObjectUtil';
import { useNumberFormatter } from '@/modules/core/charts/am5/gauge/composables/useNumberFormatter';
import { useToolTips } from '@/modules/core/charts/am5/pie/composables/useTooltips';

export function useLegend(context) {
  const { root, chart, config, isDarkTheme } = context();
  const { formatNumber } = useNumberFormatter(context);
  const { assignThemeColor } = useToolTips(context);

  /**
   * Create a legend
   * @param dataItems optionally pass in a set of dataItems to generate the legend from
   * @param settings optionally pass in a settings object to customise the legend
   *                  include manualMarkers: true to skip the default styling of the markers
   * @param push whether the legend gets pushed into the chart or not
   * @returns {*}
   */
  function createLegend(dataItems = chart.value.series.values, settings = {}, push = true) {
    const legend = generateInitialLegend(settings, push);
    const { isStacked, isFullStacked } = config.value;
    const legendSeriesData = isStacked || isFullStacked ? dataItems.slice().reverse() : dataItems;
    legend.data.setAll(legendSeriesData);
    return legend;
  }

  /**
   * Ensure each legend item gets formatted properly
   * docs say to target legend.labelValues or legend.labels.template but they don't
   * exist in this context.  target each one individually so we can properly format
   * @param legend
   */
  function formatLegendItems(legend) {
    const fontColorPicker = assignThemeColor(isDarkTheme, config.value);
    legend.children.each((childItem) => {
      const valueLabel = childItem.children.getIndex(2);

      valueLabel.adapters.add(Constant.TEXT, (text, target) => {
        if (!target.dataItem) {
          return text;
        }
        const { dataContext } = target.dataItem;
        const value = config.value.isNormalized ? dataContext.rawValue : dataContext.value;
        const format = dataContext.dataFormat ? dataContext.dataFormat : dataContext.dataItemFormat;

        return `[${fontColorPicker}]${formatNumber(value, format, true)}`;
      });
    });
  }

  /**
   * Create and push a legend into the chart
   * @param settings
   * @param push
   * @returns {*}
   */
  function generateInitialLegend(settings, push) {
    const scrollbar = generateScrollbar();
    const isInReportStudio = config.value.isReportStudioWidget();
    const maxHeightComparison =
      config.value.data.priorPeriodData && config.value.data.priorPeriodData.length > 0 ? 55 : 85;
    const maxHeight = !isInReportStudio ? maxHeightComparison : percent(100);
    const fontColorPicker = assignThemeColor(isDarkTheme, config.value);

    const legendSettings = omitNils({
      nameField: Constant.NAME,
      fillField: Constant.COLOR,
      strokeField: Constant.COLOR,
      marginTop: 10,
      marginBottom: 0,
      maxHeight,
      verticalScrollbar: scrollbar,
      width: p100,
    });

    const legend = Legend.new(root.value, { ...legendSettings, ...settings });

    legend.labels.template.setAll({
      fontSize: 12,
      fill: color(fontColorPicker),
    });

    legend.valueLabels.template.setAll({
      fontSize: 12,
      textAlign: Position.RIGHT,
    });

    if (!push) {
      return legend;
    }
    const legendItem = chart.value.children.push(legend);
    restrictNumberOfLegendItems(legendItem);
    return legendItem;
  }

  function restrictNumberOfLegendItems(legend, container = null, callback = () => {}) {
    const { isExporting } = config.value;

    if (isExporting && !config.value.isReportStudioWidget()) {
      const message = i18n.$t(
        'Some legend items are hidden due to space constraints in the widget'
      );
      const labelContainer = container ?? chart.value;
      const warningLabel = labelContainer.children.push(
        Label.new(root.value, {
          // eslint-disable-next-line tap/no-raw-text-js
          text: `[fontStyle: italic]${message}[/]`,
          fontSize: 10,
          textAlign: Constant.CENTER,
          width: p100,
          lineHeight: 1,
          visible: false,
          paddingBottom: 0,
          marginBottom: 0,
        })
      );
      legend.get(Constant.VERTICAL_SCROLLBAR).setAll({
        forceHidden: true,
      });
      legend.events.on(WidgetEvents.BOUNDSCHANGED, () => {
        const isLabelVisible = legend._contentHeight > 85;
        warningLabel.set(Constant.VISIBLE, isLabelVisible);
        callback(isLabelVisible);
      });
    } else if (config.value.isReportStudioWidget()) {
      legend.get(Constant.VERTICAL_SCROLLBAR).setAll({
        forceHidden: true,
      });
      chart.value.events.on(WidgetEvents.BOUNDSCHANGED, () => {
        const halfHeight = chart.value.height() / 2;
        const contentHeight = legend._contentHeight;
        legend.setAll({ height: Math.min(halfHeight, contentHeight) });
      });
    }
  }

  function generateScrollbar() {
    return Scrollbar.new(root.value, {
      orientation: Constant.VERTICAL,
    });
  }

  return {
    createLegend,
    formatLegendItems,
    generateInitialLegend,
    restrictNumberOfLegendItems,
  };
}
