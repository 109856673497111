'use strict';
import angular from 'angular';
import dashboardFilterPanelHtmlSring from './dashboardfilterpanel.html';

angular.module('dashboardfilterpanel.directives', [])

    .component('dashboardFilterPanel', dashboardFilterPanel());

function dashboardFilterPanel() {
    return {
        templateUrl: dashboardFilterPanelHtmlSring,
        controllerAs: 'vm',
        controller: DashboardFilterPanelController
    };
}

/**
 * @ngInject
 */
function DashboardFilterPanelController(
    AppFactory,
    DashboardFilterPanelFactory,
    ExecutiveSummaryFactory,
    DesignFactory,
    $SlidePanelEvents,
    $UISelect2Events,
    $DashboardFilterHeaderEvents,
    $scope,
    $element,
    PubSub
) {
    var vm = this;
    vm.isSuperAdmin = AppFactory.getUser().isSuperAdmin();
    vm.$onInit = $onInit;
    vm.$postLink = $postLink;
    vm.$onDestroy = $onDestroy;
    vm.applyFilters = applyFilters;
    vm.resetFilterValues = resetFilterValues;

    registerEvents();

    function $onInit() {
        vm.shared = DashboardFilterPanelFactory.shared;
    }

    function $postLink() {
        $element.appendTo(document.body);
    }

    function $onDestroy() {
        vm.shared.closePanel();
        $element.remove();
    }

    function applyFilters() {
        vm.shared.applyFilters();
        ExecutiveSummaryFactory.resetClientSelect();
        $scope.$root.$broadcast($DashboardFilterHeaderEvents.UPDATE);
    }

    function resetFilterValues() {
        vm.shared.resetFilterValues(true);
        $scope.$broadcast($UISelect2Events.RESET);
        $scope.$root.$broadcast($DashboardFilterHeaderEvents.UPDATE);
    }

    function registerEvents() {
        PubSub.on($SlidePanelEvents.CLOSE_ALL, function (sourcePanel) {
            if (sourcePanel !== DashboardFilterPanelFactory.shared.panelId) {
                vm.shared.closePanel();
            }
        });

        $scope.$on($UISelect2Events.CLEAR, function () {
            vm.shared.resetFilterValues(true, false, true);
            $scope.$root.$broadcast($DashboardFilterHeaderEvents.UPDATE);
        });
    }
}