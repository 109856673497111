'use strict';
import angular from 'angular';
import _ from 'lodash';
import dashboardFilterHeaderHtmlUrl from './dashboardfilterheader.html';
import {PageEntity} from "../../../design.constants";

angular.module('dashboardfilterheader.directives', [])

    .component('dashboardFilterHeader', dashboardFilterHeader());

function dashboardFilterHeader() {
    return {
        templateUrl: dashboardFilterHeaderHtmlUrl,
        controllerAs: 'vm',
        controller: DashboardFilterHeaderController
    };
}

/**
 * @ngInject
 */
function DashboardFilterHeaderController(
    $scope,
    $timeout,
    FilterParam,
    $UISelect2Events,
    $DashboardFilterHeaderEvents,
    DesignFactory,
    DashboardFilterPanelFactory,
    DashboardFilterHeaderFactory,
    WidgetFactory,
    AppFactory,
    AppModule,
    UIColor
) {
    var vm = this;
    vm.FilterParam = FilterParam;
    vm.refreshActiveFilters = true;
    vm.showFilterValues = false;
    vm.isDashboardFilterValuesModuleAvailable = AppFactory.getUser().isModuleAvailable(AppModule.DASHBOARD_FILTER_VALUES);
    vm.toggleShowFilterValues = toggleShowFilterValues;
    vm.getFilterValuesIconClass = getFilterValuesIconClass;
    vm.getTooltipValueForFilterValuesIcon = getTooltipValueForFilterValuesIcon;

    registerEvents();

    vm.$onInit = function() {
        vm.shared = DashboardFilterHeaderFactory.shared;
    };

    vm.$postLink = function() {
        var $getAllWidgetElements = DesignFactory.$getAllWidgetElements;
        vm.hoverIn = function(filter) {
            if (filter.id_name.toLowerCase() === PageEntity.PAGE_GOALS) {
                filter.dataSource = {}; // For goals no datasource available and set defaults for filter.
                filter.dataSource.type = "goal_data";
                filter.dataSource.id = null;
                filter.dataSource.data_view = null;
            }
            $getAllWidgetElements().addClass('faded');
            _.each(DashboardFilterPanelFactory.getAffectedWidgetIds(filter.dataSource, filter.is_live_integration), function(id) {
                WidgetFactory.$getElement(id)
                    .find('div.widget-inner')
                    .removeClass('faded')
                    .addClass('glow')
                    .css({outlineColor: filter.dataSource.color});
            });
        };
        vm.hoverOut = function() {
            $getAllWidgetElements().removeClass('glow').removeClass('faded');
        };
    };

    vm.$onDestroy = function() {
        //$element.remove();
    };

    vm.resetFilters = function() {
        vm.shared.resetFilters();
        $scope.$root.$broadcast($UISelect2Events.RESET);
        DashboardFilterHeaderFactory.setActiveFilters();
    };

    vm.getTooltipValues = function(filter) {
        return _.map(filter.values, 'text').join(', ');
    };

    vm.getValueText = function(filtersLength) {
        return filtersLength === 1 ? 'value' : 'values';
    }

    vm.getFilterValue = function(value) {
        return gettextCatalog.getString("VIEWING {{filterValue}} DATA", { filterValue: `<label class="badge badge-info">${value}</label>`});
    }

    vm.getDataWareHouseFilterValue = function(value) {
        return gettextCatalog.getString("VIEWING {{filterValue}} DATA WIDGETS", { filterValue: `<label class="badge badge-info">Data Source Type - ${value}</label>`});
    }

    vm.getTextColorWithBackgroundColor = function (backgroundColor) {
        return UIColor.textColorWithBackgroundColor(backgroundColor);
    }

    vm.getFilterDataSourceName = function (filter) {
      return filter.dataSource?.name ?? filter.id_name;
    };

    function registerEvents() {
        $scope.$on($DashboardFilterHeaderEvents.UPDATE, function () {
            DashboardFilterHeaderFactory.setActiveFilters();
            vm.refreshActiveFilters = false;
            $timeout(() => {
                vm.refreshActiveFilters = true;
            });
        });
    }

    function toggleShowFilterValues() {
        vm.showFilterValues = !vm.showFilterValues;
    }

    function getFilterValuesIconClass() {
        return `icon ${vm.showFilterValues ? 'icomoon-caret-up' : 'icomoon-caret-down'} is-flex`;
    }

    function getTooltipValueForFilterValuesIcon() {
        return vm.showFilterValues ?  'Hide Filter Values' : 'Show Filter Values';
    }
}