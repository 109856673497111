import { BaseModel } from '@/modules/core/app/models/abstracts/BaseModel';
import { ColumnFormat } from '@/modules/core/app/constants/data.constants';

export class ApiColumn extends BaseModel {
  /**
   * @var {string}
   */
  aggregate_query;

  /**
   * @var {string}
   */
  alternate_field;

  /**
   * @var { string[]}
   */
  custom_distinct_values;

  /**
   * @var { string[]}
   */
  custom_sort;

  /**
   * @var {string[]}
   */
  dependencies;

  /**
   * @var {string}
   */
  edit_control_type;

  /**
   * @var {boolean}
   */
  enable_history;

  /**
   * @var {boolean}
   */
  error;

  /**
   * @var {string}
   */
  extra_parameters;

  /**
   * @var {string}
   */
  field;

  /**
   * @var {string}
   */
  field_alias;

  /**
   * @var {boolean}
   */
  force_in_select;

  /**
   * @var {string}
   */
  foreign_mapping;

  /**
   * @var {string}
   */
  format;

  /**
   * @var {string}
   */
  groupby_field_format;

  /**
   * @var {string}
   */
  groupby_id_field;

  /**
   * @var {string}
   */
  groupby_name_field;

  /**
   * @var {boolean}
   */
  ignore_set_data;

  /**
   * @var {boolean}
   */
  is_custom_edit_control;

  /**
   * @var { boolean}
   */
  is_extra;

  /**
   * @var {boolean}
   */
  is_filterable;

  /**
   * @var {boolean}
   */
  is_groupable;

  /**
   * @var {boolean}
   */
  is_groupby_name_field;

  /**
   * @var {boolean}
   */
  is_hidden;

  /**
   * @var {boolean}
   */
  is_hidden_in_details;

  /**
   * @var {boolean}
   */
  is_metric;

  /**
   * @var {boolean}
   */
  is_primary_date_field;

  /**
   * @var {boolean}
   */
  is_primary_descriptive_name_field;

  /**
   * @var {boolean}
   */
  is_primary_key;

  /**
   * @var {boolean}
   */
  is_primary_name_field;

  /**
   * @var {boolean}
   */
  is_private;

  /**
   * @var {boolean}
   */
  is_read_only;

  /**
   * @var {boolean}
   */
  is_required;

  /**
   * @var {boolean}
   */
  allows_null_value;

  /**
   * @var {boolean}
   */
  is_selectable;

  /**
   * @var {boolean}
   */
  is_taggable;

  /**
   * @var {boolean}
   */
  is_unique;

  /**
   * @var {string}
   */
  label;

  /**
   * @var {number}
   */
  order;

  /**
   * @var {string}
   */
  placeholder;

  /**
   * @var {string}
   */
  postprocess_type;

  /**
   * @var {number}
   */
  precision;

  /**
   * @var {string}
   */
  query;

  /**
   * @var {boolean}
   */
  show_on_group_by;

  /**
   * @var {boolean}
   */
  is_sortable;

  /**
   * @var {number}
   */
  summary_order;

  /**
   * @var {string}
   */
  tag_default_value;

  /**
   * @var {string}
   */
  tooltip;

  /**
   * @var {boolean}
   */
  user_translation_field;

  /**
   * @var {string}
   */
  value_data_source_type;

  /**
   * @var {string[]}
   */
  values;

  /**
   * @var {string[]}
   */
  scope;

  /**
   * @var {boolean}
   */
  is_average;

  /**
   * @var {string}
   */
  possible_discrepancy_warning;

  /**
   * @var {boolean}
   */
  compare_as_value;

  /**
   * @var {boolean}
   */
  compare_inverted;

  /**
   * @var {string}
   */
  client_id;

  /**
   * @var {boolean}
   */
  defined_by_formula;

  /**
   * @var {boolean}
   */
  is_rank_tracking_metric;

  constructor(model = {}) {
    super();

    this.aggregate_query = model.aggregate_query;
    this.alternate_field = model.alternate_field;
    this.custom_distinct_values = model.custom_distinct_values;
    this.custom_sort = model.custom_sort;
    this.dependencies = model.dependencies;
    this.edit_control_type = model.edit_control_type;
    this.enable_history = model.enable_history;
    this.error = model.error;
    this.extra_parameters = model.extra_parameters;
    this.field = model.field;
    this.field_alias = model.field_alias;
    this.force_in_select = model.force_in_select;
    this.foreign_mapping = model.foreign_mapping;
    this.format = model.format;
    this.groupby_field_format = model.groupby_field_format;
    this.groupby_id_field = model.groupby_id_field;
    this.groupby_name_field = model.groupby_name_field;
    this.ignore_set_data = model.ignore_set_data;
    this.is_custom_edit_control = model.is_custom_edit_control;
    this.is_extra = model.is_extra;
    this.is_filterable = model.is_filterable;
    this.is_groupable = model.is_groupable;
    this.is_groupby_name_field = model.is_groupby_name_field;
    this.is_hidden = model.is_hidden;
    this.is_hidden_in_details = model.is_hidden_in_details;
    this.is_metric = model.is_metric;
    this.is_primary_date_field = model.is_primary_date_field;
    this.is_primary_descriptive_name_field = model.is_primary_descriptive_name_field;
    this.is_primary_key = model.is_primary_key;
    this.is_primary_name_field = model.is_primary_name_field;
    this.is_private = model.is_private;
    this.is_read_only = model.is_read_only;
    this.is_required = model.is_required;
    this.allows_null_value = model.allows_null_value;
    this.is_selectable = model.is_selectable;
    this.is_taggable = model.is_taggable;
    this.is_unique = model.is_unique;
    this.label = model.label;
    this.order = model.order;
    this.placeholder = model.placeholder;
    this.postprocess_type = model.postprocess_type;
    this.precision = model.precision;
    this.query = model.query;
    this.show_on_group_by = model.show_on_group_by;
    this.is_sortable = model.is_sortable;
    this.summary_order = model.summary_order;
    this.tag_default_value = model.tag_default_value;
    this.tooltip = model.tooltip;
    this.user_translation_field = model.user_translation_field;
    this.value_data_source_type = model.value_data_source_type;
    this.values = model.values;
    this.scope = model.scope;
    this.is_average = model.is_average;
    this.possible_discrepancy_warning = model.possible_discrepancy_warning;
    this.compare_as_value = model.compare_as_value ?? false;
    this.compare_inverted = model.compare_inverted ?? false;
    this.client_id = model.client_id;
    this.defined_by_formula = model.defined_by_formula;
    this.is_rank_tracking_metric = model.is_rank_tracking_metric ?? false;
  }

  isFormatString() {
    return (
      this.format === ColumnFormat.FORMAT_PHONE_NUMBER ||
      this.format === ColumnFormat.FORMAT_STRING ||
      this.format === ColumnFormat.FORMAT_ID
    );
  }

  isFormatInteger() {
    return (
      this.format === ColumnFormat.FORMAT_INTEGER ||
      this.format === ColumnFormat.FORMAT_DECIMAL ||
      this.format === ColumnFormat.FORMAT_PERCENT ||
      this.format === ColumnFormat.FORMAT_CURRENCY ||
      this.format === ColumnFormat.FORMAT_TIME
    );
  }

  isFormatNumerical() {
    return (
      this.format === ColumnFormat.FORMAT_INTEGER ||
      this.format === ColumnFormat.FORMAT_DECIMAL ||
      this.format === ColumnFormat.FORMAT_PERCENT ||
      this.format === ColumnFormat.FORMAT_CURRENCY
    );
  }

  isFormatCurrency() {
    return this.format === ColumnFormat.FORMAT_CURRENCY;
  }

  isFormatPercent() {
    return this.format === ColumnFormat.FORMAT_PERCENT;
  }

  isFormatTime() {
    return this.format === ColumnFormat.FORMAT_TIME;
  }

  isFormatEnum() {
    return this.format === ColumnFormat.FORMAT_ENUM;
  }

  isFormatDateTime() {
    return this.format === ColumnFormat.FORMAT_DATETIME;
  }

  isFormatDate() {
    return this.format === ColumnFormat.FORMAT_DATE;
  }

  isFormatDateOrDateTime() {
    return this.format === ColumnFormat.FORMAT_DATE || this.format === ColumnFormat.FORMAT_DATETIME;
  }

  isFormatBoolean() {
    return this.format === ColumnFormat.FORMAT_BOOLEAN;
  }

  canBeCumulative() {
    return this.is_metric && !this.is_average;
  }
}

export default ApiColumn;
