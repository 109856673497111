'use strict';
import angular from 'angular';
import DevTools from '../../../../devtools';
import _ from 'lodash';

import widgetHeaderHtmlUrl from './widget.header.html';
import { WidgetHeader } from "../../design.constants";

angular.module('design.widget.components', [])

    .component('widgetHeader', {
        templateUrl: widgetHeaderHtmlUrl,
        controllerAs: 'vm',
        bindings: {
            widget: '<',
            state: '<'
        },
        controller: WidgetHeaderController
    });

function WidgetHeaderController(
    PubSub,
    $timeout,
    $WidgetHeaderEvents,
    $AnnotationEvents,
    DrawOption,
    WidgetType,
    AppFactory,
    PageEntity,
    DesignFactory,
    ExportBuilderDashboardService,
    ReportStudioTemplateDataService,
    DrawOptionFactory,
    DataSourceFactory,
    LibraryFactory,
    WidgetCreateFactory,
    WidgetBuilderService,
    WidgetBuilderUIService,
    WidgetFactory,
    WidgetUtilService,
    ExportFactory,
    DataSourceType,
    DashboardContextService,
    WidgetCurrencyDataService
) {
    var vm = this;
    var _user = AppFactory.getUser();
    var _page = DesignFactory.getCurrentPage();
    var _layout = DesignFactory.getCurrentLayout();

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.getWidgetHeaderClass = getWidgetHeaderClass;
    vm.getDataSourceLink = getDataSourceLink;
    vm.getDataSourceColor = getDataSourceColor;
    vm.getDataSourceIcon = getDataSourceIcon;
    vm.showDataSourceDisplay = showDataSourceDisplay;
    vm.showWidgetTitle = showWidgetTitle;
    vm.canIncludeDataSource = canIncludeDataSource;
    vm.showWidgetDataSource = showWidgetDataSource;
    vm.getWidgetTitleClass = getWidgetTitleClass;
    vm.widgetHasLink = widgetHasLink;
    vm.widgetHasClientDiscrepancy = widgetHasClientDiscrepancy;
    vm.widgetHasLiveDataDiscrepancy = widgetHasLiveDataDiscrepancy;
    vm.geoWidgetHasSampleData = geoWidgetHasSampleData;
    vm.getWidgetTitlePadding = getWidgetTitlePadding;
    vm.canDisplayFilterToggler = canDisplayFilterToggler;
    vm.canIncludeFilterToggler = canIncludeFilterToggler;
    vm.canDisplayDateRangeFilterToggler = canDisplayDateRangeFilterToggler;
    vm.canIncludeDateRangeFilterToggler = canIncludeDateRangeFilterToggler;
    vm.canDisplayAnnotationToggler = canDisplayAnnotationToggler;
    vm.canIncludeAnnotationToggler = canIncludeAnnotationToggler;
    vm.canDisplayAlertToggler = canDisplayAlertToggler;
    vm.canIncludeAlertToggler = canIncludeAlertToggler;
    vm.canShowOptions = canShowOptions;
    vm.widgetHasErrorClass = widgetHasErrorClass;
    vm.canIncludeDevTools = canIncludeDevTools;
    vm.canDisplayGoalOptionContainer = canDisplayGoalOptionContainer;
    vm.widgetHasDataSourceLink = widgetHasDataSourceLink;
    vm.titleNeedsRefresh = false;
    vm.DrawOption = DrawOption;
    vm.pageIsDynamic = _page.is_dynamic;
    vm.isExportingReportStudio = isExportingReportStudio;
    vm.getDataSourceIconForExport = getDataSourceIconForExport;
    vm.getRequiredPaddingClass = getRequiredPaddingClass;
    vm.widgetSupportState = widgetSupportState;
    vm.widgetSupportCountry = widgetSupportCountry;
    vm.getWidgetHeaderHeight = getWidgetHeaderHeight;
    vm.getDataSourceStyle = getDataSourceStyle;
    vm.getWidgetPlainTitle = WidgetFactory.getWidgetPlainTitle;
    vm.getWidgetTitle = getWidgetTitle;
    vm.isHTML = WidgetFactory.isHTML;
    vm.appendOffset = WidgetFactory.appendOffset;

    function $onInit() {
        _registerEvents();
    }

    function $onDestroy() {
        _unregisterEvents();
    }

    /**
     * @returns {string}
     */
    function getWidgetHeaderClass() {
        var classNames = [];
        if (vm.widget.height <= 1 || !_resolveDrawOptionValue(DrawOption.SHOW_HEADER)) {
            classNames.push('hidden');
        }
        if (!_resolveDrawOptionValue(DrawOption.SHOW_BORDERS)) {
            classNames.push('transparent-border');
        }
        return classNames.join(' ');
    }

    /**
     * Returns true if the data source link can be shown
     * @returns {boolean}
     */
    function widgetHasDataSourceLink() {
        if (!vm.widget.metadata.data_source || vm.widget.has_live_integration) {
            return false;
        }
        let dataSource = vm.widget.metadata.data_source;
        switch (dataSource.type) {
            case DataSourceType.SERVICE_DATA:
                    return _user.showServiceDrilldowns;

            case DataSourceType.CATEGORY_DATA:
                return _user.showDrilldownsWhenOverviewDisabled || _user.showCategoriesOverview;

            case DataSourceType.GOAL_DATA:
                return true;

            default:
                return false;
        }
    }

    /**
     * Return proper data source link
     * @returns {string}
     */
    function getDataSourceLink() {
        if (!vm.widgetHasDataSourceLink()) {
            return '';
        }

        let link;
        let dataSource = vm.widget.metadata.data_source;
        switch (dataSource.type) {
            case DataSourceType.SERVICE_DATA:
                link = window.isNUI
                    ? (function() {
                        return _user.isClient()
                            ? "#/services/drilldown/" + dataSource.id + "/cgn"
                            : "#/services/drilldown/" + dataSource.id + "/client";
                    })()
                    : "#/service/details/" + dataSource.id;
                break;

            case DataSourceType.CATEGORY_DATA:
                link = window.isNUI
                    ? (function () {
                        return _user.isClient()
                            ? "#/categories/drilldown/" + dataSource.id + "/cgn"
                            : "#/categories/drilldown/" + dataSource.id + "/client";
                    })()
                    : "#/category/details/" + dataSource.id;
                break;

            case DataSourceType.GOAL_DATA:
                link = window.isNUI
                ? '#/sup/goals'
                : '#/goals';
                break;

            default:
                link = '';
                break;
        }

        return link;
    }

    /**
     * Determines whether or not to show the title of a widget
     * @returns {boolean}
     */
    function showWidgetTitle() {
        return _resolveDrawOptionValue(DrawOption.SHOW_TITLE);
    }

    /**
     * @returns {boolean}
     */
    function canIncludeDataSource() {
        var dataSource = vm.widget.metadata.data_source;
        if (dataSource) {
            return (dataSource.is_of_type_service && dataSource.id)
                || DataSourceFactory.isGoalDataSourceType(dataSource.type)
        } else {
            return false;
        }
    }

    /**
     * @returns {boolean}
     */
    function showWidgetDataSource() {
        return _resolveDrawOptionValue(DrawOption.SHOW_DATA_SOURCE);
    }

    /**
     * Determine which data source icon to display
     * @returns {string}
     */
    function getDataSourceIcon() {
        return DataSourceFactory.getDataSourceIcon(vm.widget.metadata.data_source);
    }

    /**
     * export icons can't use custom-icon css classes, just the icon name
     * @returns {*}
     */
    function getDataSourceIconForExport() {
        return _.last(_.split(getDataSourceIcon(), ' '));
    }

    /**
     * Determine which data source color
     * @returns {string}
     */
    function getDataSourceColor() {
        return DataSourceFactory.getDataSourceColor(vm.widget.metadata.data_source);
    }

    /**
     * Determines whether or not widget should show data source name and data view name
     * @returns {boolean}
     */
    function showDataSourceDisplay() {
        // Only show data source name and data view as title on data source overview and client details
        return (_page.entity === PageEntity.PAGE_OVERVIEW
            || _page.entity === PageEntity.PAGE_CLIENT_DETAILS
            || _page.entity === PageEntity.PAGE_CATEGORIES_OVERVIEW)
            && !LibraryFactory.getIsShowingItemDetails();
    }

    /**
     * Returns an error class if widget contains an API call error (DEV ONLY)
     * @returns {boolean}
     */
    function widgetHasLink() {
        var metadata = vm.widget.metadata;
        if (!_.isNil(vm.widget.is_export)) {
            return vm.widget.is_export;
        }
        if (metadata.data_source) {
            return DataSourceFactory.dataSourceContainsServices(metadata.data_source.type)
                || DataSourceFactory.isGoalDataSourceType(metadata.data_source.type);
        }
        return false;
    }

    /**
     * @returns {boolean}
     */
    function widgetHasClientDiscrepancy() {
        return WidgetCurrencyDataService.getDiscrepancy(vm.widget);
    }

    /**
     * @returns {boolean}
     */
    function widgetHasLiveDataDiscrepancy() {
        return (vm.widget.has_live_integration) && (vm.widget.metadata?.dynamic?.raw_data?.data?.has_data_discrepancy);
    }


    /**
     * @returns {*}
     */
    function widgetSupportState() {
        if (vm.widget && vm.widget.type !== WidgetType.GEOCHART) {
            return true
        }
        return _.get(vm.widget.metadata.data_source, 'geo_columns.support_state')
    }

    /**
     * @returns {*}
     */
    function widgetSupportCountry() {
        if (vm.widget && vm.widget.type !== WidgetType.GEOCHART) {
            return true
        }
        return _.get(vm.widget.metadata.data_source, 'geo_columns.support_country')
    }

    /**
     * @returns {boolean}
     */
    function geoWidgetHasSampleData() {
        if (!vm.widget || vm.widget.type !== WidgetType.GEOCHART) {
            return false;
        }

        return WidgetFactory.useSampleData(vm.widget.metadata) || DashboardContextService.isDemoModeEnabled();
    }

    /**
     * Class for widget title
     * @returns {string}
     */
    function getWidgetTitleClass() {
        var classNames = [DashboardContextService.resolveFontSize()];
        if (!showWidgetTitle()) {
            classNames.push('hidden');
        }

        if (!_.isNull(vm.widget.report_id) && !isExportingReportStudio()) {
            let canDisplayFilterToggler = vm.canDisplayFilterToggler();
            let canDisplayDateRangeFilterToggler = vm.canDisplayDateRangeFilterToggler();

            if (canDisplayFilterToggler && canDisplayDateRangeFilterToggler) {
                classNames.push('filter-date-toggles');
            } else if (canDisplayFilterToggler || canDisplayDateRangeFilterToggler) {
                classNames.push('filter-or-date-toggle');
            }
        }
        return classNames.join(' ');
    }

    function getWidgetTitle() {
        let offset;
        if (!_.isNull(vm.widget.report_id) && isExportingReportStudio() && vm.widget.paginate_details && vm.widget.paginate_details.offset) {
            offset = vm.widget.paginate_details.offset;
            if (!vm.isHTML(vm.widget.title)) {
                let prefix = "";
                if (offset) {
                    prefix = ` [Cont.] - ${offset} `;
                }
                return `${vm.widget.title}${prefix}`
            }
            return vm.appendOffset(vm.widget.title, offset);
        }
        return vm.widget.title;
    }

    /**
     * Determine padding of widget title
     * @returns {string}
     */
    function getWidgetTitlePadding(hasLink = false) {
        if (vm.widget.title === '') {
            return '0px';
        }

        if (DesignFactory.getIsExportingPage()) {
            return '70px';
        }

        // Need to account for any alert,filter,etc... toggler widths
        var spacing = 24;
        if (canDisplayDataSource()) {
            spacing += 72;
        }

        if (vm.widget.title.includes("text-align:right")) {
          spacing += 60;
        }

        /**
         * For widgets with no link, there is unwanted padding
         * so, need to remove the below spacing to make it look good
         */
        if (!hasLink) {
            spacing -= 70;
        }

        return spacing + 'px';
    }

    /**
     * @private
     * Determines whether or not widget contains filters
     * @returns {boolean}
     */
    function widgetHasFilters() {
        // Don't show toggler if no filters are present or if we are exporting
        return vm.widget.metadata.dynamic
            && !_.isEmpty(vm.widget.metadata.dynamic.filters)
            && !DesignFactory.getIsExportingPage()
            && !vm.widget.metadata.dynamic.is_live_filter;
    }

    /**
     * Resolves ng-show for filter-toggler
     * @returns {boolean}
     */
    function canDisplayFilterToggler() {
        return widgetHasFilters();
    }

    /**
     * Resolves ng-if for filter-toggler
     * @returns {boolean}
     */
    function canIncludeFilterToggler() {
        return !ExportFactory.getIsExporting() && !WidgetUtilService.isMediaWidget(vm.widget.type);
    }

    /**
     * Resolves ng-show for date-range-filter-toggler
     * @returns {boolean}
     */
    function canDisplayDateRangeFilterToggler() {
        return vm.widget.metadata.is_overriding_date_range;
    }

    /**
     * Resolves ng-if for date-range-filter-toggler
     * @returns {boolean}
     */
    function canIncludeDateRangeFilterToggler() {
        return canIncludeFilterToggler();
    }

    /**
     * Resolves ng-show for annotation-toggler
     * Determines whether or not widget contains annotations
     * @returns {boolean}
     */
    function canDisplayAnnotationToggler() {
        // Don't show toggler if annotations are not present
        return !vm.widget.is_predefined
            && !_.isEmpty(vm.widget.metadata.dynamic.annotations);
    }

    /**
     * Resolves ng-if for annotation-toggler
     * @returns {boolean}
     */
    function canIncludeAnnotationToggler() {
        return !vm.widget.is_export && !WidgetUtilService.isMediaWidget(vm.widget.type);
    }

    /**
     * @returns {boolean|*}
     */
    function isExportingReportStudio() {
        return DesignFactory.getIsExportingPage()
            && ReportStudioTemplateDataService.getIsActive();
    }

    /**
     * Determines whether or not widget contains alerts
     * @returns {boolean}
     */
    function widgetHasAlerts() {
        // Don't show toggler if alerts are not present
        return !vm.widget.is_predefined
            && vm.widget.metadata.dynamic
            && !_.isEmpty(vm.widget.metadata.dynamic.alerts);
    }

    /**
     * Resolves ng-show for alert-toggler
     * @returns {boolean}
     */
    function canDisplayAlertToggler() {
        return widgetHasAlerts();
    }

    /**
     * Resolves ng-if for alert-toggler
     * @returns {boolean}
     */
    function canIncludeAlertToggler() {
        var widget = vm.widget;
        var userHasPermission = _user.isAdmin();
        return userHasPermission
            && !widget.is_export
            && !WidgetUtilService.isMediaWidget(widget.type)
            && Permission.hasPermission(Permission.moduleName.WIDGET, Permission.permissionKey.CAN_EDIT_WIDGET_ALERTS, AppFactory.getUser().isSuperAdmin())
            && Permission.hasPermissionToWrite(Permission.moduleName.ALERT);
    }

    /**
     * @returns {boolean}
     */
    function canShowOptions() {
        return !ExportFactory.getIsExporting()
            && !vm.state.isBuilding
            && !vm.state.isEditing
            && !vm.state.isPreview
            && _page.entity !== PageEntity.PAGE_OVERVIEW
            && _page.entity !== PageEntity.PAGE_CATEGORIES_OVERVIEW
            && !WidgetBuilderUIService.isActive();
    }

    /**
     * Returns an error class if widget contains an API call error (DEV ONLY)
     * @returns {string}
     */
    function widgetHasErrorClass() {
        if (canIncludeDevTools()) {
            var devWidget = DevTools.widgets[vm.widget.id];
            return !_.isEmpty(devWidget) && !_.isEmpty(devWidget.bug) ? 'has-error' : '';
        }
    }

    /**
     * Resolves ng-if for dev-tool
     * @returns {*}
     */
    function canIncludeDevTools() {
        var state = vm.state;
        return _user.hasDevTools
            && !state.isBuilding
            && !state.isPreview
            && !vm.widget.is_export
            && DesignFactory.getCurrentLayout().metadata.is_packed;
    }

    /**
     * Resolves ng-if for option container
     * @returns {*}
     */
    function canDisplayGoalOptionContainer() {
        return _page.entity === PageEntity.PAGE_GOALS;
    }

    /**
     * Determine if data source logo can be displayed
     * @returns Boolean
     */
    function canDisplayDataSource() {
        var metadata = vm.widget.metadata;
        var canShowDataSource = _canShowDataSource();
        var widgetDrawOption = metadata.draw_options;
        if (widgetDrawOption
            && !_.isUndefined(widgetDrawOption[DrawOption.SHOW_DATA_SOURCE])
            && widgetDrawOption[DrawOption.SHOW_DATA_SOURCE].overridden) {
            canShowDataSource = metadata.draw_options[DrawOption.SHOW_DATA_SOURCE].value;
        }

        return canShowDataSource;
    }

    //TODO: @dannyyassine somewhere else?
    function _canShowDataSource() {
        if (vm.widget.is_export) {
            return vm.widget.metadata.draw_options[DrawOption.SHOW_DATA_SOURCE].value;
        } else if (ReportStudioTemplateDataService.getIsActive()) {
            return ExportBuilderDashboardService.getBuilder().report.metadata.draw_options[DrawOption.SHOW_DATA_SOURCE].value;
        } else {
            return _layout.metadata.draw_options[DrawOption.SHOW_DATA_SOURCE].value;
        }
    }

    /**
     * @private
     */
    function _refreshTitle() {
        vm.titleNeedsRefresh = true;
        $timeout(function() {
            vm.titleNeedsRefresh = false;
        }, 0);
    }

    /**
     * @private
     * Will resolve the appropriate draw options based on the layout/widget inheritance tree
     * @returns {*}
     */
    function _resolveDrawOptionValue(key) {
        return DrawOptionFactory.resolveDrawOptionValue(key, vm.widget, vm.widget.is_export);
    }

    function _initAnnotation() {
        vm.state.isAnnotating = true;
        _bindAnnotationEvents();
    }

    /**
     *
     * @param newAnnotation
     * Keep annotations object up to date when adding annotations
     * @private
     */
    function _addAnnotation(newAnnotation) {
        var annotations = vm.widget.metadata.dynamic.annotations;
        if (_.isNull(annotations)) {
            vm.widget.metadata.dynamic.annotations = annotations = [];
        }
        var annotationIndex = _.findIndex(annotations, {id: newAnnotation.id});
        if (annotationIndex > -1) {
            annotations[annotationIndex] = newAnnotation;
        } else {
            annotations.push(newAnnotation);
        }
    }

    /**
     *
     * @param annotationId
     * Keep annotations object up to date when deleting annotations
     * @private
     */
    function _deleteAnnotation(annotationId) {
        var annotations = vm.widget.metadata.dynamic.annotations;
        _.remove(annotations, {id: annotationId});

        if (_.isEmpty(annotations)) {
            vm.widget.metadata.dynamic.annotations = null;
        }
    };

    function _closePanelEvent() {
        vm.state.isAnnotating = false;
        _unbindAnnotationEvents();
    }

    /**
     * @private
     */
    function _registerEvents() {
        PubSub.on($WidgetHeaderEvents.REFRESH_TITLE + vm.widget.id, _refreshTitle);
        PubSub.on($AnnotationEvents.INIT_WIDGET + vm.widget.id, _initAnnotation);
    }

    /**
     * @private
     */
    function _unregisterEvents() {
        PubSub.off($WidgetHeaderEvents.REFRESH_TITLE + vm.widget.id, _refreshTitle);
        PubSub.off($AnnotationEvents.INIT_WIDGET + vm.widget.id, _initAnnotation);
    }

    /**
     * @private
     */
    function _bindAnnotationEvents() {
        PubSub.on($AnnotationEvents.ADD_ANNOTATION, _addAnnotation);
        PubSub.on($AnnotationEvents.DELETE_ANNOTATION, _deleteAnnotation);
        PubSub.on($AnnotationEvents.CLOSE_PANEL, _closePanelEvent);
    }

    /**
     * @private
     */
    function _unbindAnnotationEvents() {
        PubSub.off($AnnotationEvents.ADD_ANNOTATION, _addAnnotation);
        PubSub.off($AnnotationEvents.DELETE_ANNOTATION, _deleteAnnotation);
        PubSub.off($AnnotationEvents.CLOSE_PANEL, _closePanelEvent);
    }

    function getRequiredPaddingClass() {
        var classNames = [];
        if(vm.widget.title || widgetHasClientDiscrepancy()) {
            classNames.push('p-t-7');
        }
        return classNames.join(' ');
    }

    /**
     * Determine max height of widget title
     * @returns {string}
     */
    function getWidgetHeaderHeight() {
        var selector = "'widget-"+vm.widget.id+"'";

        if (vm.widget.title === '') {
            return '0px';
        }
        // checking whether show active filters button or show custom date range button is clicked or not
        if (vm.state.showFilterPanel || vm.state.showDateRangeFilterPanel) {
            return WidgetHeader.HEADER_REGULAR_HEIGHT + 'px';
        }
        // Cannot use design factory dimension functions since we need to
        // get a pixel value of the width NOT a percentage
        var $widget = WidgetFactory.$getElement(vm.widget.id, vm.widget.is_export);

        var widgetHeaderHeight;

        widgetHeaderHeight = $widget[0]?.firstElementChild?.firstElementChild?.firstElementChild?.firstElementChild?.lastElementChild;

        if (!_.isNull(vm.widget.layout_id)) {
            widgetHeaderHeight = widgetHeaderHeight?.lastElementChild?.clientHeight;
        } else if (!_.isNull(vm.widget.report_id)) {
            widgetHeaderHeight = widgetHeaderHeight?.clientHeight;
        }

        if (!widgetHeaderHeight) {
            widgetHeaderHeight = WidgetHeader.HEADER_REGULAR_HEIGHT;
        }

        return Math.max(widgetHeaderHeight, WidgetHeader.HEADER_REGULAR_HEIGHT) + 'px';
    }

    /**
     * Determine style for widget datasource icon
     * @returns {string}
     */
    function getDataSourceStyle() {
        var widgetHeader = getWidgetHeaderHeight();

        if (widgetHeader !== '45px') {
            return 'translateY(50%)';
        }
        return 'translateY(0%)';
    }
}
