import { DataProcessor, percent } from '@amcharts/amcharts5';
import {
  BackgroundGradientType,
  Constant,
  SeriesType,
} from '@/modules/core/charts/am5/charts.constants';
import { ColumnSeries } from '@amcharts/amcharts5/xy';
import { RadarColumnSeries } from '@amcharts/amcharts5/radar';
import { useXYAxes } from '@/modules/core/charts/am5/base/composables/axis/useXYAxes';
import { color } from '@/modules/core/charts/am5/charts.helper';
import { omitNils } from '@/modules/core/app/utils/ObjectUtil';
import { getChartConfig } from '@/modules/core/charts/am5/bar/config/chartconfig';
import { useGradient } from '@/modules/core/charts/am5/base/composables/fills/useGradient';
import { ColumnFormat } from '@/modules/core/app/constants/data.constants';
import { useXYTooltip } from '@/modules/core/charts/am5/base/composables/useXYTooltip';
import { useAxes } from '@/modules/core/charts/am5/base/composables/axis/useAxes';
import { useNumberFormatter } from '@/modules/core/charts/am5/gauge/composables/useNumberFormatter';
import { isNil } from 'lodash';

export function useXYSeries(context) {
  const { root, chart, config, isDarkTheme } = context();
  const { getXAxis, getYAxis, getSeriesAxesProps } = useXYAxes(context);
  const { getCategoryAxis } = useAxes(context);
  const { createLinearGradient } = useGradient(context);
  const { getNewDefaultTooltip } = useXYTooltip(context);
  const { getLegendPlaceholderString } = useNumberFormatter(context);

  function createAllSeries() {
    const { isStacked, isFullStacked, series } = config.value;
    const seriesIterator = isStacked || isFullStacked ? series.slice().reverse() : series;
    seriesIterator.forEach((props) => {
      createSeries(props);
    });
  }

  function createColumnSeries() {
    const { isStacked, isFullStacked, series } = config.value;
    const seriesIterator = isStacked || isFullStacked ? series.slice().reverse() : series;
    seriesIterator.forEach((props) => {
      if (props.seriesType === SeriesType.COLUMN) createSeries(props);
    });
  }

  function applyDataToAllSeries() {
    if (!isNil(config.value.series)) {
      checkNullForMetrics();
    }

    chart.value.series.values.forEach((series) => series.data.setAll(config.value.data));
  }

  function isRotatedGraph() {
    return config.value.isRadialBar || config.value.isRotated;
  }

  function createSeries(props) {
    const SeriesClass = getSeriesClass(props.seriesType);
    const xAxis = getXAxis(isRotatedGraph() ? props.axisIndex : props.categoryAxisIndex);
    const yAxis = getYAxis(isRotatedGraph() ? props.categoryAxisIndex : props.axisIndex);
    const series = chart.value.series.push(
      SeriesClass.new(root.value, {
        xAxis,
        yAxis,
        ...getGenericSeriesProps(props),
        ...getTypeSpecificSeriesProps(props),
        adjustBulletPosition: false,
      })
    );

    series.set(Constant.USER_DATA, props);

    if (props.seriesType === SeriesType.COLUMN) {
      const { isRotated, fillType, isLayered, lollipopChart, isCurvedColumns, isClustered } =
        config.value;

      const dimensionProp = isRotated ? Constant.HEIGHT : Constant.WIDTH;
      const dimensionValue = lollipopChart ? 1 : null;
      series.columns.template.setAll(
        omitNils({
          [dimensionProp]: isLayered ? percent(props.seriesWidth) : dimensionValue,
        })
      );

      const { shadowProps, cornerProps } = getChartConfig(config.value);
      if (fillType !== BackgroundGradientType.SOLID && !lollipopChart) {
        const angle = fillType === BackgroundGradientType.LINEAR_Y_GRADIENT ? 0 : 90;
        const fillGradient = createLinearGradient(['', config.value.gradientColor], angle);
        series.columns.template.setAll({ fillGradient });
      }
      series.columns.template.setAll({
        ...shadowProps,
        ...cornerProps,
        opacity: props.opacity,
        fillOpacity: config.value.isSparkLine ? props.fillOpacity : props.opacity,
      });

      if (isCurvedColumns && (isLayered || isClustered)) {
        const axis = getCategoryAxis();
        axis.get(Constant.RENDERER).set(Constant.CELL_START_LOCATION, 0);
        axis.get(Constant.RENDERER).set(Constant.CELL_END_LOCATION, 1);
        series.columns.template.set(Constant.DRAW, (display, target) => {
          const w = target.getPrivate(Constant.WIDTH, 0);
          const h = target.getPrivate(Constant.HEIGHT, 0);
          if (isRotated) {
            display.moveTo(0, 0);
            display.bezierCurveTo(w / 4, h / 4, w, h / 4, w, h / 2);
            display.bezierCurveTo(w, h - h / 4, w - w / 4, h - h / 4, 0, h);
          } else {
            display.moveTo(0, h);
            display.bezierCurveTo(w / 4, h, w / 4, 0, w / 2, 0);
            display.bezierCurveTo(w - w / 4, 0, w - w / 4, h, w, h);
          }
        });
        const propName = isRotated ? Constant.HEIGHT : Constant.WIDTH;
        series.columns.template.setAll({
          [propName]: percent(120),
        });
      }
    }

    if (
      config.value.hasRankingMetrics &&
      props.seriesType === SeriesType.COLUMN &&
      props.isRankingMetric
    ) {
      invertRankingColumns(series);
    }

    if (props.fill && !config.value.isSparkLine) {
      series.fills.template.setAll({
        visible: true,
        fillOpacity: props.fillOpacity,
      });
    }

    series.data.processor = DataProcessor.new(root.value, {
      numericFields: [props.value],
    });
    return series;
  }

  /**
   * By default, "inverse" columns come down from the top
   * We want them to rise up from the bottom so take the height (or width if rotated)
   * and minus that from the bounds of the parent's parent (the container)
   * That leaves us with a column of the correct size
   * @param series
   */
  function invertRankingColumns(series) {
    series.columns.template.set(Constant.DRAW, (display, target) => {
      const h = target.getPrivate(Constant.HEIGHT, 0);
      const w = target.getPrivate(Constant.WIDTH, 0);
      const absH = Math.abs(h);

      let originX = 0;
      let originY = target.getPrivate(Constant.Y, 0);
      let targetWidth = target.getPrivate(Constant.WIDTH, 0);
      let targetHeight = target.parent.parent._localBounds.bottom - absH;

      if (config.value.isRotated) {
        originX = -Math.abs(target.parent.parent._localBounds.right);
        originY = target.getPrivate(Constant.Y, 0);
        targetWidth = target.parent.parent._localBounds.right + w;
        targetHeight = target.getPrivate(Constant.HEIGHT, 0);
      }

      display.drawRect(originX, originY, targetWidth, targetHeight);
    });
  }

  function getGenericSeriesProps(props) {
    const { hasTooltip } = config.value;
    const tooltip = hasTooltip ? getNewDefaultTooltip() : null;

    return omitNils({
      name: props.name,
      fill: color(props.color),
      legendLabelText: props.name,
      legendValueText: getLegendPlaceholderString(props.value, props.dataItemFormat),
      // empty value so it doesn't display a currency symbol after mouseout event
      legendRangeValueText: '',
      maskBullets: true,
      tooltip,
    });
  }

  function getTypeSpecificSeriesProps(props) {
    const seriesAxesProps = getSeriesAxesProps(props);
    switch (props.seriesType) {
      case SeriesType.COLUMN: {
        const { seriesOptions, layeredProps } = getChartConfig(config.value);
        if (props.dataItemFormat === ColumnFormat.FORMAT_TIME) {
          delete seriesOptions.stacked;
        }
        return omitNils({
          ...seriesAxesProps,
          ...seriesOptions,
          ...layeredProps,
          stroke: isDarkTheme.value ? props.strokeColor : color(props.color),
          locationX: props.locationX,
        });
      }
      default:
        return {};
    }
  }

  function getSeriesClass(type) {
    const isRadarCharts = config.value.isRadialHistogram || config.value.isRadialBar;
    switch (type) {
      case SeriesType.COLUMN:
        return isRadarCharts ? RadarColumnSeries : ColumnSeries;
      default:
        // eslint-disable-next-line tap/no-raw-text-js
        Logger.log(`SeriesType ${type} is not supported`, Logger.LEVEL_ERROR);
    }
  }

  /**
   * In Certain cases null values of the metrics not showing in the chart, so assigning null values to 0 only for metrics
   */
  function checkNullForMetrics() {
    const seriesKeys = config.value.series.map((series) => series.value);
    config.value.data.forEach((data) => {
      seriesKeys.forEach((key) => {
        data[key] = data[key] ?? 0; // Using nullish coalescing operator
      });
    });
  }

  return {
    createAllSeries,
    applyDataToAllSeries,
    createSeries,
    createColumnSeries,
  };
}
