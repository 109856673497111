'use strict';
import angular from 'angular';
import _ from 'lodash';

angular.module('widget.serialchart.ctrls', [])

    .controller('SerialChartWidgetController', SerialChartWidgetController);

/**
 * @ngInject
 */
function SerialChartWidgetController(
    $scope,
    DrawOption,
    ChartFactory,
    WidgetBuilderService,
    YAxisPosition,
    SerialChartFactory
) {
    $scope.$on('chart:setMetadataDefaults', function(e, metadata, chartType) {
        setMetadataDefaults(metadata, chartType);
        if (WidgetBuilderService.getWidgetModel()) {
            WidgetBuilderService.setDrawOptions(metadata.draw_options);
        }
    });

    function setMetadataDefaults(metadata, chartType) {
        var drawOptions = {};
        drawOptions[DrawOption.PLOT_TYPE] = metadata.draw_options[DrawOption.PLOT_TYPE] || ChartFactory.getDefaultPlotType(chartType);
        drawOptions[DrawOption.DATE_FORMAT_TYPE] = SerialChartFactory.getDefaultTimeFormatType(metadata.draw_options);
        drawOptions[DrawOption.HAS_LEGEND] = false;
        drawOptions[DrawOption.IS_ROTATED] = false;
        drawOptions[DrawOption.HAS_VALUE_SCROLLER] = false;
        drawOptions[DrawOption.SHOW_LABELS] = true;
        drawOptions[DrawOption.IS_Y_AXIS_MOVED] = false;
        drawOptions[DrawOption.Y_AXIS_OVERRIDE] = YAxisPosition.DEFAULT;
        drawOptions[DrawOption.IS_NORMALIZED] = false;
        drawOptions[DrawOption.IS_ZERO_SCALED] = true;
        drawOptions[DrawOption.HAS_BULLETS] = true;
        drawOptions[DrawOption.HAS_TOOLTIP] = true;
        drawOptions[DrawOption.IS_SMOOTHED_LINE] = false;
        drawOptions[DrawOption.HIDE_GRID_LINES] = true;
        drawOptions[DrawOption.DEPTH] = 0;
        drawOptions[DrawOption.ANGLE] = 0;
        drawOptions[DrawOption.SHOW_METRIC_LABELS] = false;
        drawOptions[DrawOption.SHOW_EMPTY_DATES] = false;
        drawOptions[DrawOption.IS_HAND_DRAWN] = false;
        metadata.draw_options = _.assign(drawOptions, metadata.draw_options);

        if (_.isEmpty(metadata.draw_options[DrawOption.PLOT_TYPE])) {
            metadata.draw_options.plot_type = ChartFactory.getDefaultPlotType(chartType);
        }
    }
}